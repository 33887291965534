import React from 'react';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import { Row, Col, Container } from 'react-bootstrap';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../components/ArticleCard/ArticleCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const GrowerKnowledge = (props) => {
  const postList = props.data.allMarkdownRemark;
  return (
    <>
      <HeaderExpanded title='News' />
      <Container className='my-3'>
        <Row>
          {postList.edges.map(({ node }, i) => (
            <Col key={i} lg={6} className='my-3'>
              <div className='hp-article-card'>
                <Link to={node.fields.slug} key={i} className='hp-article'>
                  {node.frontmatter.image && (
                    <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                  )}
                  <Container>
                    <Row className='py-3'>
                      <Col xs={12} md={11}>
                        <h1 className='hp-article-title'>
                          {node.frontmatter.title}
                        </h1>
                        <p>{node.excerpt}</p>
                        <small>{node.frontmatter.date}</small>
                      </Col>
                      <Col
                        xs={1}
                        className='align-items-center justify-content-center d-none d-md-flex'
                      >
                        <FontAwesomeIcon size='2x' icon={faChevronRight} />
                      </Col>
                    </Row>
                  </Container>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default GrowerKnowledge;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            image {
              childImageSharp {
                resize(width: 400, height: 320) {
                  src
                }
                fluid(maxWidth: 520) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;